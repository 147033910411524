import React, { useState } from "react"

const AboutUs = () => {
  // const [active, setActive] = useState(1)
  return (
    <section className="video-box-design-guide sec-pad service-tab-box">
      <div className="thm-container">
        <div className="single-tab-content tab-pane show fade in active animated fadeIn">
          <div className="sec-title text-center mb0">
            <span>About Us</span>
            <h3>Our Introduction</h3>
            <p>
              Manjushreee Multi-Graphic Press Pvt. Ltd. first opened its doors
              to customers in 2065 B.S., situated in Nayabazaar, Kathmandu,
              Nepal. Customer Service and affordable printing has been our focus
              for over 12 years, and till now we remain committed to offering
              the highest quality printing services at a very competitive price.
              We provide affordable printing at the highest quality with
              competitive price. Our services includes all offset and digital
              printing with graphic designing.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
