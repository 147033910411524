import React from "react"
import Footer from "../components/footer"
import HeaderOne from "../components/header-one"
import Layout from "../components/layout"
import MenuContextProvider from "../context/menu-context"
import PageHeader from "../components/page-header"
import AboutUs from "../components/about-us"

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Us">
        <HeaderOne />
        <PageHeader title="About Us" name="About" />
        <AboutUs />
        {/* <ClientCarousel /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  )
}

export default AboutPage
